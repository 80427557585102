import * as React from 'react'
import { graphql, navigate } from 'gatsby'
import get from 'lodash/get'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import Grid from '@material-ui/core/Grid'
import Layout from '../components/layout'
import Seo from '../components/seo'
import lifetimeplan from '../assets/images/lifetimeplan.jpg'
import promocode from '../assets/images/promocode.jpg'
import edgepartners from '../assets/images/edgepartners.jpg'

const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'

class OffersPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      viewportWidth: isClient ? window.innerWidth : 0,
    }
  }
  changeCookie(cookieName, newState) {
    const { cookies } = this.props
    cookies.set(cookieName, newState, { path: '/' })
    this.setState({ cookieName: newState })
  }
  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth })
  }
  componentDidMount() {
    this.changeCookie('lastpage', '/offers')
    if (!this.props.allCookies.firstload) {            
      setTimeout(() => {
        navigate('/loading')
      }, 500)
    }
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
  }
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')
    const pageName = 'Promotional Offers'

    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }

    var textcol = 7
    var imagecol = 3
    var spacercol = 2
    if (isMobile) {
      console.log(isMobile)
      textcol = 12
      imagecol = 12
      spacercol = 0
    }
    console.log(isMobile)
    console.log(pagewidth)

    return (
      <React.Fragment>
        <Seo pagename={pageName + ' ' + siteTitle} keywords={keywords} />
        <Layout data={this.props} pagewidth={pagewidth.matches}>
          <div className="page_wrapper generalpage_wrapper">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h1 className="generalpage_majorheading">{pageName}</h1>
              </Grid>
              <Grid item xs={12}>
                <div className="promocontainer">
                  <Grid container spacing={0}>
                    <Grid item xs={textcol}>
                      <h3 className="promo_h2">Lifetime Subscriptions</h3>
                    </Grid>
                    <Grid item xs={imagecol} />
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={imagecol} className="promoimage_cont">
                      <img className="promoimage" src={lifetimeplan} />
                    </Grid>
                    <Grid item xs={textcol}>
                      <div className="promocodetext">                        
                        Sign up now for our Pre-Launch offer to get <em>LIFETIME ACCESS </em> 
                        for the one-off price of <em>$100 USD</em>. Limited to the first 
                        5000 subscribers, you better act fast. As we progress to the official launch of EdgeForecaster, we are 
                        fine tuning the artificial intelligence models, testing the 
                        platform, optimising the data processing, and completing 
                        development of the mobile app.
                        <br />
                        <br />
                        We want to reward those who see the potential in a market tool 
                        like EdgeForecaster and those who want to be an early adopter 
                        of what will soon be essential for anyone wanting to actively 
                        trade. Our Pre-Launch offer gives complete, <em>LIFETIME ACCESS </em> 
                        to the EdgeForecaster platform, including all future development 
                        releases to anyone who signs up over the current period prior 
                        to our official launch.                         
                      </div>
                    </Grid>
                    <Grid item xs={spacercol} />
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="promocontainer">
                  <Grid container spacing={0}>
                    <Grid item xs={textcol}>
                      <h3 className="promo_h2">Promo Codes</h3>
                    </Grid>
                    <Grid item xs={imagecol} />
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={imagecol} className="promoimage_cont">
                      <img className="promoimage" src={promocode} />
                    </Grid>
                    <Grid item xs={textcol}>
                      <div className="promocodetext">
                        All EdgeForecaster subscribers receive a promo-code to
                        give out to their family, friends, and followers.
                        Whoever signs up with this promo-code receives a 10%
                        discount on any subscription. Once five(5) people use
                        this promo-code to become subscribers, the original
                        subscriber receives their EdgeForecaster subscription
                        for free (or receive a refund if they have purchased a
                        Lifetime Subscription).
                      </div>
                    </Grid>
                    <Grid item xs={spacercol} />
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="promocontainer">
                  <Grid container spacing={0}>
                    <Grid item xs={textcol}>
                      <h3 className="promo_h2">Edge Partners</h3>
                    </Grid>
                    <Grid item xs={imagecol} />
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={imagecol} className="promoimage_cont">
                      <img className="promoimage" src={edgepartners} />
                    </Grid>
                    <Grid item xs={textcol}>
                      <div className="promocodetext">
                        The step up from the promo-code discount is our Edge
                        Partner program. For those who have twenty(20) of their
                        family, friends, or followers subscribe to
                        EdgeForecaster using a promo-code, qualify to an Edge
                        Partner.
                        <br />
                        <br />
                        Edge Partners receive 10% of the revenue from all
                        subscribers who sign up using their promo code, for as
                        long as they remain as part of the Edge Partner Program.
                      </div>
                    </Grid>
                    <Grid item xs={spacercol} />
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default withCookies(OffersPage)
export const OffersPageQuery = graphql`
  query OffersPageQuery {
    site {
      siteMetadata {
        title
        description
        menuLinks {
          name
          link
          cl
        }
        keywords
      }
    }
    sitePage {
      pluginCreator {
        name
      }
    }
  }
`
